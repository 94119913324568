const { SITE_NAME, BRAND_NAME } = process.env;

export default ({ service, providers }) => {
	if (!providers || !service) return [];
	const response = [
		{
			"@context": "https://schema.org",
			"@type": "BreadcrumbList",
			"itemListElement": [
				{
					"@type": "ListItem",
					"position": 1,
					"item": {
						"@id": `https://${SITE_NAME}.com/`,
						"name": BRAND_NAME,
						"url": `https://${SITE_NAME}.com/`,
					},
				},
				{
					"@type": "ListItem",
					"position": 2,
					"item": {
						"@id": `https://${SITE_NAME}.com${service.url}`,
						"name": `${service.phrase.ucFirst()} near me`,
						"url": `https://${SITE_NAME}.com${service.url}`,
					},
				},
			],
		},
	];

	const itemLists = providers.map((provider, index) => {
		const item = {
			// "@context": "https://schema.org",
			"@type": "LocalBusiness",
			"name": `${provider.company_name.ucFirst()}`,
			"image": `${provider.image?.value}`,
			//TODO ADD PRICE RANGE IF SET & SAMEAS = ANY SOCIAL LINKS SET
			//"priceRange": "",
			// "sameAs": [
			//     "facebook...",
			//     "twitter...",
			//     "etc...",
			// ],
			//"openingHours": "Mo,Tu,We,Th 09:00-12:00",
			//"paymentAccepted":"Cash, Credit Card, Bitcoin",
			//"numberOfEmployees": 3,
			"url": `https://${SITE_NAME}.com/${provider.profile.url}`,
		};
		if (provider.company_employees) {
			item["numberOfEmployees"] = provider.company_employees;
		}

		if (provider.social) {
			item["sameAs"] = Object.values(provider.social);
		}

		if (provider.accepted_payment_types) {
			item["paymentAccepted"] = provider.accepted_payment_types.join(", ");
		}

		if (!provider.reviews || provider.reviews.length === 0)
			return {
				"@type": "ListItem",
				"position": index + 1,
				"item": item,
			};

		// var bestRating = 0;
		// var worstRating = 5;
		// var averageRating = 0;
		// provider.reviews.forEach((review) => {
		// 	if (bestRating < parseFloat(review.rating)) {
		// 		bestRating = parseFloat(review.rating);
		// 	}

		// 	if (worstRating > parseFloat(review.rating)) {
		// 		worstRating = parseFloat(review.rating);
		// 	}
		// 	averageRating += parseFloat(review.rating);
		// });

		// averageRating = averageRating / provider.reviews.length;

		item["aggregateRating"] = {
			"@type": "AggregateRating",
			"ratingValue": `${provider.metrics.reviews_avg?.toFixed(2)}`,
			"reviewCount": `${provider.metrics.reviews_count}`,
			"bestRating": `${provider.best_review?.rating?.toFixed(0) || 0}`,
			"worstRating": `${provider.worst_review?.rating?.toFixed(0) || 5}`,
		};

		// item["review"] = (provider.reviews || []).map((review) => {
		// 	const published = new Date(review.created_at);
		// 	const publishedYear = published.getFullYear();
		// 	const publishedMonth =
		// 		published.getMonth() + 1 > 9
		// 			? published.getMonth() + 1
		// 			: "0" + (published.getMonth() + 1);
		// 	const publishedDay =
		// 		published.getDate() > 9
		// 			? published.getDate()
		// 			: "0" + published.getDate();
		// 	const authorName = review.author;

		// 	return [
		// 		{
		// 			"@type": "Review",
		// 			"datePublished": `${publishedYear}-${publishedMonth}-${publishedDay}`,
		// 			"description": `${review.message.body
		// 				.replace(/"/g, "")
		// 				.replace(/'/g, "")}`,
		// 			"author": {
		// 				"@type": "Person",
		// 				"name": `${authorName}`,
		// 			},
		// 			"reviewRating": {
		// 				"@type": "Rating",
		// 				"ratingValue": `${review.rating}`,
		// 			},
		// 		},
		// 	];
		// });
		return {
			"@type": "ListItem",
			"position": index + 1,
			"item": item,
		};
	});
	return response.concat({
		"@context": "https://schema.org",
		"@type": "ItemList",
		"itemListElement": itemLists,
	});
};
