import React, { memo } from "react";
import PropTypes from "prop-types";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

const { SITE_NAME } = process.env;

const NearMeBreadcrumbs = memo(({ service }) => {
	return (
		<Box
			component="section"
			pt={4}
			pr={2}
			pl={2}
			color={"#616161"}
			fontSize={14}
		>
			<Container maxWidth="md">
				<Breadcrumbs
					separator={"›"}
					aria-label="breadcrumb"
					color="inherit"
					style={{ fontSize: 14 }}
				>
					<Link color="inherit" href="/" underline={"hover"}>
						{SITE_NAME.ucFirst()}
					</Link>
					<Link color="inherit" href={service.url} underline={"hover"}>
						{service.phrase.ucFirst()}
					</Link>
				</Breadcrumbs>
			</Container>
		</Box>
	);
});

NearMeBreadcrumbs.propTypes = {
	service: PropTypes.shape({
		phrase: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
	}).isRequired,
};

export default React.memo(NearMeBreadcrumbs);
