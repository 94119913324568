import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import useStyles from "@hooks/useStyles";
import HeaderSearchBar from "@components/Main/Shared/HeaderSearchBar";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Footer from "@components/Main/Shared/Footer";
import Button from "@material-ui/core/Button";
import useStaticContext from "@libs/framework/src/hooks/useStaticContext";

const { BRAND_NAME } = process.env;

const NearMe = () => {
	const { services } = useStaticContext();
	return (
		<React.Fragment>
			<HeaderSearchBar />

			<Box component="section" p={"40px 16px"} minHeight="100vh">
				<Container maxWidth="lg">
					{/* <Box mb={6} display="flex" alignItems="center">
						<Button
							href={"/near-me"}
							color={"secondary"}
							size="large"
							disableElevation
						>
							Services
						</Button>
						<Box ml={2} mr={2}>
							&bull;
						</Box>
						<Button
							href={"/sitemap/locations"}
							color={"secondary"}
							size="large"
							disableElevation
						>
							Locations
						</Button>
						<Box ml={2} mr={2}>
							&bull;
						</Box>
						<Button
							href={"/sitemap/profiles"}
							color={"secondary"}
							size="large"
							disableElevation
						>
							Profiles
						</Button>
					</Box> */}
					<Box
						component={"h1"}
						fontSize={{ xs: 24, sm: 32 }}
						fontWeight={700}
						mb={10}
					>
						Services near me
					</Box>

					<Grid container spacing={4}>
						{services.map(({ key, slug, phrase }) => (
							<Grid key={key} item xs={12} sm={6} md={4}>
								<Link href={"/" + slug} color={"primary"}>
									{phrase}
								</Link>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>

			<Footer />
		</React.Fragment>
	);
};

export default NearMe;
export const loader = (route) =>
	loadable(() => import("./NearMe.component"), { ssr: !!route.ssr });
