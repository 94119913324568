import React, { memo } from "react";
import PropTypes from "prop-types";

import SearchPanel from "../Shared/SearchPanel";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import useStyles from "@hooks/useStyles";
//import useConstants from "@hooks/useConstants";
import useStaticContext from "@hooks/useStaticContext";

const { SITE_NAME } = process.env;

const NearMeBanner = memo(({ service, midtail, quickPickQuestions }) => {
	const classes = useStyles("Info");
	//const { mobile } = useConstants("NearMeBannerViewModel");

	const { app } = useStaticContext();
	const { city } = app.location || {};

	const targetService = midtail || service;

	const heroPicture =
		SITE_NAME === "tutors"
			? "https://res.cloudinary.com/liaison-inc/image/upload/c_fit,f_auto,q_auto,{SIZE_REPLACE}/services/tutors/backgrounds/math.jpg"
			: `https://res.cloudinary.com/liaison-inc/image/upload/c_fit,f_auto,q_auto,{SIZE_REPLACE}/services/${SITE_NAME}/backgrounds/${targetService.name
					.replace(" & ", "-")
					.replace(",", "")
					.replace(/ /g, "-")}.jpg`;

	// const heroPictureOG = `https://res.cloudinary.com/liaison-inc/image/upload/c_fill,f_auto,q_auto/services/${SITE_NAME}/backgrounds/${targetService.name
	// 	.replace(" & ", "-")
	// 	.replace(",", "")
	// 	.replace(/ /g, "-")}.jpg`;

	// const heroPictureMobile =
	// 	SITE_NAME === "tutors"
	// 		? "https://res.cloudinary.com/liaison-inc/image/upload/c_fill,f_auto,q_auto,{SIZE_REPLACE}/services/tutors/backgrounds/math.jpg"
	// 		: `https://res.cloudinary.com/liaison-inc/image/upload/c_fill,f_auto,q_auto,{SIZE_REPLACE}/services/${SITE_NAME}/backgrounds/${targetService.name
	// 				.replace(" & ", "-")
	// 				.replace(",", "")
	// 				.replace(/ /g, "-")}.jpg`;

	// const heroPictureMobileOG = `https://res.cloudinary.com/liaison-inc/image/upload/c_fill,f_auto,q_auto,w_600/services/${SITE_NAME}/backgrounds/${targetService.name
	// 	.replace(" & ", "-")
	// 	.replace(",", "")
	// 	.replace(/ /g, "-")}.jpg`;

	const onlineService = targetService.phrase.toLowerCase().includes("online");

	const h1 = `Find ${
		targetService.plural ? targetService.plural : targetService.phrase
	}${onlineService ? "" : " near me"}`;

	const subheader = `Share a few details and we'll show you the best ${
		targetService.plural_2
			? targetService.plural_2
			: targetService.plural
			? targetService.plural.toLowerCase()
			: targetService.phrase.toLowerCase()
	}${onlineService ? "." : city ? " in " + city + "." : " near you."}`;

	const replaceSize = (src, size) => src.replace("{SIZE_REPLACE}", size);

	return (
		<React.Fragment>
			<section className={classes.hero}>
				<picture className={classes.picture}>
					<source
						srcSet={`${replaceSize(
							heroPicture,
							"h_200,w_400"
						)} 400w, ${replaceSize(
							heroPicture,
							"h_400,w_768"
						)} 768w, ${replaceSize(heroPicture, "w_1200")} 1200w`}
						sizes="100vw"
					/>
					<img
						sizes="100vw"
						srcSet={`${replaceSize(
							heroPicture,
							"h_200,w_400"
						)} 400w, ${replaceSize(
							heroPicture,
							"h_400,w_768"
						)} 768w, ${replaceSize(heroPicture, "w_1200")} 1200w`}
						src={replaceSize(heroPicture, "w_1200")}
						alt={`Find a ${
							targetService.singular
								? targetService.singular.toLowerCase()
								: targetService.phrase.toLowerCase()
						} ${onlineService ? "" : "near you"}`}
						className={classes.backgroundImage}
						fetchpriority="high"
					/>
				</picture>

				<Box
					height="100%"
					display={{ xs: "flex", sm: "none" }}
					alignItems="flex-end"
					position="relative"
					color="#FFF"
					py={4}
					px={4}
					className={classes.pictureGradient}
				>
					<h1 className={classes.h1}>{h1}</h1>
				</Box>

				<Box
					width="100%"
					left={0}
					top="50%"
					pr={2}
					pl={2}
					className={classes.heroBox}
				>
					<Container maxWidth="md">
						<Box
							display={{ xs: "none", sm: "block" }}
							p={8}
							borderRadius={12}
							maxWidth={505}
							boxShadow={5}
							bgcolor="#FFF"
						>
							<SearchPanel
								id={"big"}
								h1={h1}
								subheader={subheader}
								quickPickQuestions={quickPickQuestions}
							/>
						</Box>
					</Container>
				</Box>

				{/* <Box
					display={{ xs: "none", sm: "none", md: "block" }}
					position="absolute"
					textAlign="right"
					color="#FFF"
					className={classes.floatingText}
					p={6}
					bottom={0}
					right={0}
				>
					{`100+ ${service.plural.ucFirst()} near you`}
				</Box> */}
			</section>

			<Box
				display={{ xs: "block", sm: "none" }}
				p={4}
				maxWidth="100%"
				bgcolor="#FFF"
			>
				<SearchPanel
					id={"small"}
					h1={h1}
					subheader={subheader}
					quickPickQuestions={quickPickQuestions}
				/>
			</Box>
		</React.Fragment>
	);
});

NearMeBanner.propTypes = {
	service: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
	quickPickQuestions: PropTypes.array,
	zipcode: PropTypes.string,
};

export default React.memo(NearMeBanner);
