import React, { memo } from "react";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import useStaticContext from "@hooks/useStaticContext";

import { observer } from "mobx-react";

import { Box, Container, Avatar, Grid } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

import LazyLoadComponent from "@custom/LazyLoad";

const NearMeReviews = observer(({ service }) => {
	const classes = useStyles("Info");
	const { nearMeStore: store } = useStores();

	const { app } = useStaticContext();
	const { city } = app.location || {};

	return (
		<section className={classes.reviewsSection}>
			<Container maxWidth="md">
				<Box py={6}>
					<h2 className={classes.reviewsH2}>
						Reviews for {city && city}{" "}
						{service.plural_2
							? service.plural_2
							: service.plural
							? service.plural.toLowerCase()
							: service.phrase.toLowerCase()}
					</h2>
					<Box color={"grey.700"} mb={8} lineHeight={1.5}>
						{`Recent success stories from people in ${
							city ? "the " + city : "your"
						} area.`}
					</Box>
					<Grid container spacing={6}>
						{store.showcasePros.map((provider) => (
							<Grid item xs={12} sm={6} md={4}>
								<div className={classes.reviewsBox}>
									<div className={classes.reviewsAuthor}>
										{provider.reviews[1].author.ucFirst()}
									</div>

									<Rating
										value={provider.reviews[1].rating}
										precision={0.5}
										readOnly
										size="small"
									/>

									<div className={classes.reviewsBody}>
										{provider.reviews[1].message?.body}
									</div>

									<div className={classes.reviewsCompany}>
										<LazyLoadComponent>
											<Avatar
												imgProps={{
													loading: "lazy",
													decoding: "async",
												}}
												alt=""
												src={provider.image?.value}
											>
												{provider.company_name}
											</Avatar>
										</LazyLoadComponent>
										<div className={classes.reviewsCompanyName}>
											{provider.company_name}
										</div>
									</div>
								</div>
							</Grid>
						))}
					</Grid>
				</Box>
			</Container>
		</section>
	);
});

export default React.memo(NearMeReviews);
