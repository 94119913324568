import React, { useEffect, Suspense } from "react";
import { observer } from "mobx-react";

import useStaticContext from "@hooks/useStaticContext";
import useError from "@hooks/useError";
import useStores from "@hooks/useStores";

import Header from "@components/Main/Shared/Header";
import Footer from "@components/Main/Shared/Footer";
import ProList from "@components/Global/Custom/ProList/ProList";
import HowItWorks from "@components/Main/Shared/HowItWorks";
import CoursesAndGuides from "@components/Main/Shared/CoursesAndGuides";
import FAQ from "@components/Main/Shared/FAQ";
import { LeadFunnelModal } from "@components/Main/Shared/LeadFunnel";

import SchemaOrg from "@components/Global/Custom/SchemaOrg";
import schema from "./@data/schema.org";
import NearMeBanner from "./NearMeBanner";
import NearMeProSection from "./NearMeProSection";
import NearMeBreadcrumbs from "./NearMeBreadcrumbs";
//import NearMeMidtailNav from './NearMeMidtailNav';
import NearMeCities from "./NearMeCities";

import { setCookieByName, getCookieByName } from "@utils/Browser";
import NearMeReviews from "./NearMeReviews";
import LazyLoadComponent from "@custom/LazyLoad";

const NearMe = observer(() => {
	const ctx = useStaticContext();
	const [ErrorTemplate, onError] = useError();
	const { nearMeStore: store, leadFunnelModalStore } = useStores();
	const {
		quickPickQuestions,
		providers,
		service,
		midtail,
		popularCities,
		related_services,
	} = ctx;
	const { zipcode } = ctx.app?.location || {};

	useEffect(() => {
		if (!("geolocation" in navigator)) return;
		if (!ctx?.app?.location?.lat || !ctx?.app?.location?.lng) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const lat = parseFloat(getCookieByName("location.lat"));
					const lng = parseFloat(getCookieByName("location.lng"));
					const { latitude, longitude } = position.coords;
					if (
						latitude.toFixed(2) !== lat.toFixed(2) ||
						longitude.toFixed(2) !== lng.toFixed(2)
					) {
						setCookieByName("location.lat", latitude, 0);
						setCookieByName("location.lng", longitude, 0);
						window.location.reload();
					}
				},
				(error) => {
					onError(error);
				}
			);
		}
	}, []);

	useEffect(() => {
		if (leadFunnelModalStore?.show) {
			store.hasShowLeadFunnelModal = true;
		}
	}, [leadFunnelModalStore?.show]);

	const seeMoreOnClickHandler = () => (window.location.href = store.searchLink);

	return (
		<React.Fragment>
			<Header searchBar={false} />
			<NearMeBanner
				zipcode={zipcode}
				quickPickQuestions={quickPickQuestions}
				service={service}
				midtail={midtail}
			/>
			<NearMeBreadcrumbs service={midtail || service} />
			<NearMeProSection service={midtail || service}>
				{/*<NearMeMidtailNav/>*/}
				<ProList
					providers={providers}
					seeMoreOnClickHandler={seeMoreOnClickHandler}
				/>
			</NearMeProSection>
			<FAQ service={midtail || service} />
			<NearMeReviews service={midtail || service} />
			<HowItWorks />
			<NearMeCities
				service={midtail || service}
				popularCities={popularCities}
			/>
			<CoursesAndGuides
				related_services={related_services}
				service={midtail || service}
			/>
			<SchemaOrg schema={schema({ service: midtail || service, providers })} />
			<ErrorTemplate />
			{store.hasShowLeadFunnelModal && (
				<LeadFunnelModal
					provider={store.provider}
					zipcode={store.zipcode}
					service={store.service}
					midtail={midtail}
					base64EncodedAnswerKeys={store.base64EncodedAnswerKeys}
				/>
			)}
			<Footer />
		</React.Fragment>
	);
});

export default NearMe;
