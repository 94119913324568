import React, { memo } from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";
import useStaticContext from "@hooks/useStaticContext";

import { Box, Container, Zoom, Tooltip, Button } from "@material-ui/core";
import { adjective as proAdjective } from "@language/provider";
import { adjective as clientAdjective } from "@language/client";

import FindInPageIcon from "@material-ui/icons/FindInPage";
import VerifiedIcon from "@components/Global/Custom/Icons/VerifiedIcon";

const { BRAND_NAME } = process.env;

const NearMeProSection = memo(({ service, ...props }) => {
	const classes = useStyles("Info");
	const { leadFunnelModalStore } = useStores();
	const { providers } = useStaticContext();
	const onlineService = service.phrase.toLowerCase().includes("online");
	const goOnClickHandler = () => {
		if (leadFunnelModalStore) {
			leadFunnelModalStore.leadActionType = "requested_quote";
			leadFunnelModalStore.show = true;
		}
	};
	return (
		<section className={classes.proSection}>
			<Container maxWidth="md">
				<Box pb={6} borderBottom={"1px solid rgb(238, 238, 238)"}>
					<Box display={"flex"} alignItems={{ xs: "flex-start", sm: "center" }}>
						<Tooltip
							enterDelay={400}
							title={`${BRAND_NAME} verifies all ${proAdjective.plural} and ensures they match your search. Our ${proAdjective.plural} have reviews from real customers and pay for visibility on our site.`}
							arrow
							TransitionComponent={Zoom}
							placement={"top"}
						>
							<Box display={"flex"} alignItems="center">
								<VerifiedIcon className={classes.proSectionVerifiedIcon} />
							</Box>
						</Tooltip>
						<h2 className={classes.proSectionH2}>
							{`Top 10 ${
								service.plural_2
									? service.plural_2
									: service.plural
									? service.plural.toLowerCase()
									: service.phrase.toLowerCase()
							}${onlineService ? "" : " near you"}`}
						</h2>
					</Box>
					<div className={classes.proSectionSubHeader}>
						{service.plural
							? `${clientAdjective.plural.ucFirst()} agree: these ${service.plural.toLowerCase()} are highly rated for knowledge, experience, communication, and more.`
							: `${clientAdjective.plural.ucFirst()} agree: these ${
									proAdjective.plural
							  } are highly rated for knowledge, experience, communication, and more.`}
					</div>
				</Box>

				{providers.length > 0 && props.children}
				{/* <Box textAlign="center" mt={16}>
							<Button variant="outlined" size="large" color="primary">
								See more
							</Button>
						</Box> */}

				{providers.length === 0 && (
					<Box
						display={"flex"}
						flexDirection={"column"}
						flex={"1 1 auto"}
						height={"100%"}
					>
						<Box
							display={"flex"}
							height={{ xs: "calc(100vh - 260px)", sm: "calc(100vh - 66px)" }}
							justifyContent={"center"}
							alignItems={"center"}
						>
							<Box textAlign={"center"} maxWidth={380}>
								<FindInPageIcon style={{ fontSize: 64, color: "#b0c4de" }} />
								<Box fontSize={18} mb={3} mt={2} fontWeight={600}>
									Let us help you find the right {proAdjective.singular}.
								</Box>
								<Box fontSize={14} color={"grey.600"} lineHeight={1.6} mb={3}>
									No exact matches were found, but we can still help. Post a few
									details about your project and interested{" "}
									{proAdjective.plural} will reach out.
								</Box>
								<Button
									onClick={goOnClickHandler}
									color={"primary"}
									variant="contained"
									disableElevation
								>
									Get started
								</Button>
							</Box>
						</Box>
					</Box>
				)}
			</Container>
		</section>
	);
});

NearMeProSection.propTypes = {
	service: PropTypes.shape({
		phrase: PropTypes.string.isRequired,
	}).isRequired,
};

export default React.memo(NearMeProSection);
