import React, { memo } from "react";
import PropTypes from "prop-types";
import { Container, Grid, Box, Link } from "@material-ui/core";

const NearMeCities = memo(({ service, popularCities }) => {
	return (
		<>
			<Box component="section" bgcolor="grey.50" pt={16} pb={10} px={4}>
				<Container maxWidth="md">
					<Box fontSize={22} component="h2" fontWeight={600} mt={0} mb={8}>
						{`Explore to find ${service.phrase.toLowerCase()} nearby`}
					</Box>

					<Grid container spacing={4}>
						{popularCities.map((city) => (
							<Grid item xs={12} sm={6} md={4} key={city.name}>
								<Link
									underline="hover"
									color={"secondary"}
									href={`${city.url}${service.url}/`}
								>{`${service.phrase.ucFirst()} ${city.name.ucFirst()}`}</Link>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</>
	);
});

NearMeCities.propTypes = {
	service: PropTypes.shape({
		phrase: PropTypes.string.isRequired,
		url: PropTypes.string.isRequired,
	}).isRequired,
	popularCities: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			state: PropTypes.string.isRequired,
		})
	).isRequired,
};

export default React.memo(NearMeCities);
